




















import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
    name: 'ActivitySkeletonList',
    components: {}
})
export default class ActivitySkeletonList extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    public loading: boolean;
}
