





























import { Component, Vue } from 'vue-property-decorator';

import SkeletonList from './components/skeleton-list.vue';

import { ArticleGetList } from '@/api/api';
import { searchConditionalTypeEnum } from '@/utils/type-enum';

@Component({
    name: 'Activity',
    components: {
        SkeletonList
    }
})
export default class Activity extends Vue {
    public data: any = [];
    public loading: boolean = false;

    public pageParams: any = {
        PageIndex: 1,
        PageSize: 4,
        Expressionable: []
    };

    private created() {
        this.init();
    }

    private async init() {
        try {
            this.loading = true;
            // 这里的 FieldValue 值，根据总后台管理，写死
            this.pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: 7, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            const { DataList } = await ArticleGetList(this.pageParams);
            this.data = DataList;
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    public jump(item) {
        if (item.Link === '') {
            return;
        }
        window.open(item.Link);
    }
}
